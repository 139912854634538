@import 'bootstrap';
@import 'bootstrap-icons/font/bootstrap-icons.css';

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
}

a{
  color: inherit;
}

a:hover{
  color: inherit;
}

/* Scrollbar */

::-webkit-scrollbar {
  width: 6px;
  background: transparent;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.t-center {
  position: relative;
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  color: white;
}

.bg-hunghau{
  background-image: url('https://hunghau.vn/wp-content/uploads/2021/06/background-hhh-2021.jpg');
  background-size: cover;
  width: 100vw;
  height: 100vh;
}

.bg-hunghau::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgba(0, 17, 102, 0.8);
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
  z-index: 1;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.sticky{
  position: -webkit-sticky; /* Safari */
  position: fixed;
  top: 0;
  z-index: 1;
}

.sidebar{
  width: 64px;
  height: 100vh;
  background-color: rgba(0, 17, 102, 0.8);
  float: left;
}

.main{
  width: 100%;
  height: 100%;
  margin-left: 64px;
  background: #f8f9fc;
}